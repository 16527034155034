body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  transition: all .3 ease-in;
}

html {
  --scrollbarBG: transparent;
  /* --thumbBG: #8233c5;
  --thumbBG-hover: #621e9f; */
  --thumbBG: #e0e0e0;
  --thumbBG-hover: #e0e0e0;
  /* color: #a057df */
}

*::-webkit-scrollbar {
  width: 20px;
  height: 16px;
}

* {
  scrollbar-width: unset;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  /* border-radius: 6px; */
  /* border: 3px solid var(--scrollbarBG); */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--thumbBG-hover);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type="text"]:-moz-placeholder {
  text-align: right;
}

input[type="text"]:-ms-input-placeholder {
  text-align: right;
}

input[type="text"]::-webkit-input-placeholder {
  text-align: right;
}