.reactEasyCrop_Container {
  width: 100%;
  height: 100%;
}
.reactEasyCrop_Container img {
  width: 100%;
  height: 100%;
}

.reactEasyCrop_CropArea {
  width: 175px !important ;
  height: 175px !important ;
  border-radius: 50%;
}
